import React from "react"
import marked from "marked"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import { AnimatePresence } from "framer-motion"
import "../assets/scss/style.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import InitialTransition from "../components/transition"

const Pagination = props => (
  <div className="pagination -post">
    <ul>
      {props.previous && props.previous.frontmatter.template === "blog-post" && (
        <li>
          <Link to={props.previous.frontmatter.slug} rel="prev">
            <p>
              <span className="icon -left">
                <RiArrowLeftLine />
              </span>{" "}
              Previous
            </p>
            <span className="page-title">
              {props.previous.frontmatter.title}
            </span>
          </Link>
        </li>
      )}
      {props.next && props.next.frontmatter.template === "blog-post" && (
        <li>
          <Link to={props.next.frontmatter.slug} rel="next">
            <p>
              Next{" "}
              <span className="icon -right">
                <RiArrowRightLine />
              </span>
            </p>
            <span className="page-title">{props.next.frontmatter.title}</span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

export const PostTemplate = ({ title, description, html, body }) => {
  const source = marked(html || body || "")
  return (
    <article className="blog-post">
      <header className="featured-banner">
        <section className="article-header">
          {title && <h1>{title}</h1>}
          {description && <p className="excerpt">{description}</p>}
        </section>
      </header>

      <div className="contain">
        {source && (
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: source }}
          />
        )}
      </div>
    </article>
  )
}

const Post = ({ data, pageContext }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt, id } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.fluid
    : ""
  const { previous, next } = pageContext

  let props = {
    previous,
    next,
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <InitialTransition id={id} />
      <div>
        <Layout bleed={true} className="page">
          <SEO
            title={frontmatter.title}
            description={
              frontmatter.description ? frontmatter.description : excerpt
            }
            image={Image}
            article={true}
          />
          <PostTemplate {...frontmatter} html={html} />
          {(previous || next) && <Pagination {...props} />}
        </Layout>
      </div>
    </AnimatePresence>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 300)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 1980
              maxHeight: 768
              quality: 80
              srcSetBreakpoints: [350, 700, 1050, 1400]
            ) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`
